<template>
  <v-col>
    <validation-provider v-slot="{ errors }" :rules="rules" :name="name">
      <v-autocomplete
        :items="embalagens"
        :error-messages="errors"
        :value="value"
        :label="label"
        :prepend-icon="icon"
        :search-input.sync="search"
        item-text="descricao"
        item-value="id"
        @input="$emit('input', $event)"
        no-filter
      >
      </v-autocomplete>
    </validation-provider>
  </v-col>
</template>

<script>
import { mapState, mapActions } from 'vuex'

export default {
  name: 'RelatorioCargaDescargaListFiltroModalItemInputEmbalagem',
  props: {
    name: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
    icon: {
      type: String,
    },
    rules: {
      type: [Object, String],
    },
    value: {
      required: true,
    },
  },
  data() {
    return {
      search: '',
      embalagensSelecionados: [],
      searching: false,
    }
  },
  methods: {
    ...mapActions('patio', ['getEmbalagensByFornecedor', 'addEmbalagem', 'editEmbalagem']),

    async getembalagenSearch(parametros) {
      if (!Object.keys(parametros).length) return

      try {
        await this.getEmbalagensByFornecedor(this.empresaAtual.public_id)
      } catch (e) {
        console.log(e)
        if (e.response.status === 404) {
          this.errorMessage('Página não encontrada')
        }
      }
    },
  },
  computed: {
    ...mapState('auth', ['empresaAtual', 'user']),
    ...mapState('patio', ['embalagens']),
  },
  watch: {
    async search(search) {
      if (search && !this.searching) {
        search = search.replace(/[-+().//]/g, '')
        if (
          search.length > 2
        ) {
          this.searching = true
          await this.getEmbalagensByFornecedor(this.empresaAtual.public_id)
          this.searching = false
        } else if (search.length < 2) {
          this.embalagensSelecionados = []
        }
      }
    },
  },
}
</script>
