<template>
  <v-card>
    <v-card-title>Cancelamento em massa</v-card-title>
    <v-card-text>
      Você tem certeza que deseja cancelar esses agendamentos?
      <br />
      Essa ação não pode ser desfeita.
      <v-card-actions class="justify-end">
        <v-btn color="error" :disabled="quantidade < 1" @click="callback"
          >Cancelar ({{ quantidade }})</v-btn
        >
      </v-card-actions>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: 'ModalCancelarCaminhoes',
  props: {
    callback: {
      type: Function,
      required: true,
    },
    quantidade: {
      type: Number,
      required: true,
    },
  },

  data() {
    return {}
  },
  computed: {},
  methods: {},
}
</script>
