<template>
  <v-card>
    <v-card-title
      >Cancelamento em massa ({{ cancelamento.quantidade }})</v-card-title
    >
    <v-card-text>
      <v-alert dense type="info">
        Processados: <strong>{{ cancelamento.processed }}</strong>
      </v-alert>
      <v-alert dense text type="success">
        Sucesso: <strong>{{ cancelamento.success }}</strong>
      </v-alert>
      <v-alert dense outlined type="error">
        Erro: <strong>{{ cancelamento.error }}</strong>
      </v-alert>
      <v-divider />
      <v-card-actions class="justify-end">
        <v-btn
          color="error"
          @click="$emit('close')"
          :disabled="cancelamento.processed < cancelamento.quantidade"
          >Fechar</v-btn
        >
      </v-card-actions>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: 'ModalCancelandoCaminhoes',

  props: {
    cancelamento: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {}
  },

  computed: {},

  methods: {},
}
</script>
