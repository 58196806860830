<template>
  <v-container class="container-comprovante text-center text-subtitle-2">
    <div class="comprovante-content">
      <v-row id="actions">
        <v-col class="d-flex pa-8">
          <v-btn type="button" color="primary" @click="fecharComprovantePesagem">
            Fechar
          </v-btn>
          <v-spacer />
          <v-btn color="secondary" @click="imprimirComprovante">Imprimir</v-btn>
        </v-col>
      </v-row>
      <v-row class="no-margin">
        <v-col class="col-12 left-align no-padding">
          <v-img
            contain
            height="100"
            width="150"
            src="@/assets/images/master.png"
          />
        </v-col>
      </v-row>
      <v-row class="no-margin">
        <v-col class="col-12 left-align no-padding">
          <strong>{{ agendamento.fornecedor.business_name }}</strong>
        </v-col>
      </v-row>
      <br/>
      <v-row class="no-margin">
        <v-col class="col-12 left-align no-padding">
          <strong>Ticket Pesagem: </strong>
          <strong>143038</strong>
        </v-col>
      </v-row>
      <v-row class="no-margin">
        <v-col class="col-12 left-align no-padding">
          <strong>Veiculo/Cavalo: </strong>
          <strong>{{ agendamento.veiculo.placa_cavalo || defaultValue }}</strong>
        </v-col>
      </v-row>
      <v-row class="no-margin">
        <v-col class="col-12 left-align no-padding">
          <strong>Motorista: </strong>
          <strong>{{ agendamento.motorista.user.name || defaultValue }}</strong>
        </v-col>
      </v-row>
      <v-row class="no-margin">
        <v-col class="col-12 left-align no-padding">
          <strong>Navio: </strong>
          <strong>0</strong>
        </v-col>
      </v-row>
      <v-row class="no-margin">
        <v-col class="col-12 left-align no-padding">
          <strong>Tipo Operação: </strong>
          <strong>{{ agendamento.tipo_operacao.descricao }}</strong>
        </v-col>
      </v-row>
      <v-row class="no-margin">
        <v-col class="col-12 left-align no-padding">
          <strong>DI: </strong>
          <strong>0</strong>
        </v-col>
      </v-row>
      <v-row class="no-margin">
        <v-col class="col-12 left-align no-padding">
          <strong>Transportadora: </strong>
          <strong>{{
            agendamento.transportadora.business_name || defaultValue
          }}</strong>
        </v-col>
      </v-row>
      <v-row class="no-margin">
        <v-col class="col-12 left-align no-padding">
          <strong>Cliente: </strong>
          <strong>{{ agendamento.cliente.business_name || defaultValue }}</strong>
        </v-col>
      </v-row>
      <v-row class="no-margin">
        <v-col class="col-12 left-align no-padding">
          <strong>Destinatário: </strong>
          <strong>{{ agendamento.destinatario.business_name }}</strong>
        </v-col>
      </v-row>
      <v-row style="margin-top: 0px">
        <v-col style="margin-top: 0px">
          <div
            v-for="(pedido, index) in agendamento.pedidos_agendados"
            :key="index"
          >
            <v-row class="no-margin">
              <v-col class="col-12 left-align no-padding">
                <strong>SubProduto: </strong>
                <strong>{{ pedido.pedido.subProduto.descricao }}</strong>
              </v-col>
            </v-row>
            <v-row class="no-margin">
              <v-col class="col-12 left-align no-padding">
                <strong>Quantidade: </strong>
                <strong>{{ converteKg(pedido.quantidade_agendada) }}</strong>
              </v-col>
            </v-row>
            <v-row class="no-margin">
              <v-col class="col-12 left-align no-padding">
                <strong>Nota Fiscal: </strong>
                <strong>{{ pedido.numero_nfe }}</strong>
              </v-col>
            </v-row>
            <v-row class="no-margin">
              <v-col class="col-12 left-align no-padding">
                <strong>Numero Pedido: </strong>
                <strong>{{ pedido.numero_pedido }}</strong>
              </v-col>
            </v-row>
            <v-row class="no-margin">
              <v-col class="col-12 left-align no-padding">
                <strong>Peso TARA: </strong>
                <strong>{{
                  agendamento.pesagem_tara != null
                    ? agendamento.pesagem_tara.peso
                    : '-'
                }}</strong>
              </v-col>
            </v-row>
            <v-row class="no-margin">
              <v-col class="col-12 left-align no-padding">
                <strong>Peso BRUTO: </strong>
                <strong>{{
                  agendamento.pesagem_bruto != null
                    ? agendamento.pesagem_bruto.peso
                    : '-'
                }}</strong>
              </v-col>
            </v-row>
            <v-row class="no-margin">
              <v-col class="col-12 left-align no-padding">
                <strong>Peso Liquido: </strong>
                <strong>{{ agendamento.peso_liquido || '-' }}</strong>
              </v-col>
            </v-row>
            <v-row class="no-margin">
              <v-col class="col-12 left-align no-padding">
                <strong>Quebra / Sobra (KG): </strong>
                <strong>{{ agendamento.peso_liquido - converteKg(pedido.quantidade_agendada) }}</strong>
              </v-col>
            </v-row>
            <v-row class="no-margin">
              <v-col class="col-12 left-align no-padding">
                <strong>Quebra / Sobra (%): </strong>
                <strong>{{ ((agendamento.peso_liquido / converteKg(pedido.quantidade_agendada)) * 100).toFixed(2) }}%</strong>
              </v-col>
            </v-row>
            <v-row class="no-margin">
              <v-col class="col-12 left-align no-padding">
                <strong>Entrada: </strong>
                <strong>{{
                  agendamento.pesagem_tara != null
                    ? agendamento.pesagem_tara.data
                    : '-'
                }}</strong>
              </v-col>
            </v-row>
            <v-row class="no-margin">
              <v-col class="col-12 left-align no-padding">
                <strong>Saida: </strong>
                <strong>{{
                  agendamento.pesagem_bruto != null
                    ? agendamento.pesagem_bruto.data
                    : '-'
                }}</strong>
              </v-col>
            </v-row>
          </div>
        </v-col>
      </v-row>
      <v-row class="no-margin">
        <v-col class="col-12 left-align no-padding">
          <strong>Observação: </strong>
          <strong>{{ agendamento.observacao }}</strong>
        </v-col>
      </v-row>
      <v-row class="no-margin">
        <v-col class="col-12 left-align no-padding">
          <strong>Operador: </strong>
          <strong>{{ agendamento.observacao }}</strong>
        </v-col>
      </v-row>
      <v-row class="no-margin">
        <v-col class="col-12 left-align no-padding">
          <strong>Ass:________________</strong>
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>

<script>
import { toReal, date2br } from '@/mixins/convertion.js'
import {
  cota_dia_todo,
  cota_mesmo_dia,
  mostra_cota_mesmo_dia,
} from '@/mixins/cotas.js'
import { data } from './_data'
import { computed } from './_computed'
import { filters } from './_filters'
import { methods } from './_methods'
import { Pesagem } from '@/utils/pesagem'

export default {
  name: 'Comprovante',
  inject: ['fecharComprovantePesagem'],
  mixins: [
    toReal,
    date2br,
    cota_dia_todo,
    cota_mesmo_dia,
    mostra_cota_mesmo_dia,
  ],
  data,
  computed,
  filters,
  async created() {},
  methods,
  Pesagem,
}
</script>

<style>
.container-comprovante p {
  text-transform: uppercase;
  line-height: 5px;
}

.col-comprovante {
  padding: 1px;
}

/* Alinhamento à esquerda */
.left-align {
  text-align: left;
}

/* Remove margens e preenchimentos entre colunas */
.no-margin {
  margin: 0 !important;
}

.no-padding {
  padding: 0 !important;
}

/* Estilo do título */
.title-comprovante {
  font-size: 24px; /* Aumenta o tamanho do título */
  margin-bottom: 10px; /* Adiciona espaço abaixo do título */
}

/* configuração para impressora térmica */
@media print {
  .v-application--wrap,
  .v-dialog button {
    display: none;
  }

  img {
    display: block !important;
    max-width: 100% !important;
    height: auto !important;
  }

  .comprovante-content {
    background: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  }

  .v-dialog {
    display: block;
    height: 100vh;
    width: 100vw;
    margin: 0;
  }

  .v-dialog--fullscreen {
    position: absolute;
    overflow: visible;
  }

  .v-card {
    height: 100%;
    width: 100%;
  }

  .v-data-table th,
  .v-data-table td {
    font-size: 10px !important;
    margin: 0 !important;
    padding: 0 !important;
  }

  .aviso {
    font-size: 10px;
  }

  @page {
    margin: 0;
  }
}
</style>
