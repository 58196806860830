import { Sequences } from '@/utils/permissions'
import { toLocaleDateTimeString } from '@/mixins/convertion'

export function data() {
  return {
    filters: { toLocaleDateTimeString },
    Sequences,
    dialogTrocarCota: false,
    terminalDestino: null,
    dialogCobranca: false,
    dialogCancelar: false,
    dialogDelete: false,
    dialogDetails: false,
    dialogDetailsPause: false,
    dialogPesagemHistory: false,
    dialogPesagem: false,
    dialogPesoExato: false,
    dialogPausar: false,
    dialogServicoTriadoCancelar: false,
    dialogServicosTriados: false,
    dialogGerarChecklistLaudo: false,
    dialogEdicaoRapida: false,
    dialogComprovanteAgendamento: false,
    historico: [],
    detalhes: [],
    detalhesPause: [],
    servicosTriados: [],
    selecionados: [],
    agendamentoPesoExato: {},
    agendamentoTrocarCota: {},
    agendamentoSelecionado: {},
    servicoTriadoCancelar: {},
    selectMotivoCancelamento: {},
    agendamentoCobrar: {},
    agendamentoCancelar: {},
    agendamentoPause: {},
    agendamentoEncerrarProcesso: {},
    agendamentoPesagem: {},
    paginaAtual: 1,
    statusConcluido: 'concluído',
    statusTriado: 'triado',
    statusAgendado: 'agendado',
    headersServicoTriado: [
      {
        text: '#',
        align: 'start',
        sortable: false,
        value: 'id',
      },
      { text: 'Descrição', value: 'servico.descricao' },
      { text: 'Ações', value: 'actions', sortable: false },
    ],
    motivosCancelamento: [],
    terminais: [],
    motivosCancelamentoObservacao: '',
    listItensCheckList: [],
    modalChamarCaminhoes: false,
    modalCancelarCaminhoes: false,
    modalCancelandoCaminhoes: false,
    agendamentoLaudo: {},
    agendamentoEdicaoRapida: {},
    editedItemCheckList: {
      public_id: null,
      laudolimpeza: null,
      itenslaudochecklist: null,
      check: null,
      observation: '',
    },
    cancelamento: {
      success: 0,
      error: 0,
      processed: 0,
      data: [],
    },
    loading: false,
  }
}
