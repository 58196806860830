export const data = function () {
  return {
    dialogIndicadores: false,
    showDocumentos: false,
    showComprovantes: false,
    showComprovante: false,
    showComprovanteLaudo: false,
    showComprovanteAgendamento: false,
    showComprovantePesagem: false,
    showLogsIntegracoes: false,
    page: {
      title: 'Relatório de Carga/Descarga',
    },
    headers: [
      {
        habilited: false,
        block: false,
        text: 'Etapas',
        value: 'etapas',
        sortable: false,
        display: false,
      },
      {
        habilited: true,
        block: true,
        text: 'Ações',
        value: 'actions',
        sortable: false,
      },
      { habilited: true, block: true, text: 'Status', value: 'status' },
      {
        habilited: true,
        block: false,
        text: 'Veículo',
        value: 'veiculo',
      },
      {
        habilited: false,
        block: false,
        text: 'Peso TARA (KG)',
        value: 'pesagem_tara.peso',
      },
      {
        habilited: false,
        block: false,
        text: 'Data TARA',
        value: 'pesagem_tara.data',
      },
      {
        habilited: false,
        block: false,
        text: 'Peso Bruto (KG)',
        value: 'pesagem_bruto.peso',
      },
      {
        habilited: false,
        block: false,
        text: 'Data BRUTO',
        value: 'pesagem_bruto.data',
      },
      {
        habilited: false,
        block: false,
        text: 'Peso Liquido (KG)',
        value: 'peso_liquido',
      },
      {
        habilited: false,
        block: false,
        text: 'Quebra (KG)',
        value: 'peso_quebra',
      },
      {
        habilited: false,
        block: false,
        text: 'Quebra (%)',
        value: 'perc_quebra',
      },
      {
        habilited: true,
        block: false,
        text: 'Carreta 1',
        value: 'placa_carreta_1',
      },
      {
        habilited: true,
        block: false,
        text: 'Carreta 2',
        value: 'placa_carreta_2',
      },
      {
        habilited: false,
        block: false,
        text: 'ID Bunge',
        value: 'classifWebId',
      },
      {
        habilited: true,
        block: false,
        text: 'Senha',
        value: 'senhas',
      },
      {
        habilited: true,
        block: false,
        text: 'Tipo Operação',
        value: 'tipo_operacao',
      },
      {
        habilited: true,
        block: false,
        text: 'Data da Cota',
        value: 'data_cota',
      },
      {
        habilited: true,
        block: false,
        text: 'Data do Pedido',
        value: 'data_pedido',
      },
      {
        habilited: true,
        block: false,
        text: 'Agendado Em',
        value: 'agendado_em',
      },
      {
        habilited: true,
        block: false,
        text: 'Triado Em',
        value: 'triado_em',
      },
      {
        habilited: true,
        block: false,
        text: 'Agendado por',
        value: 'agendado_por',
      },
      {
        habilited: true,
        block: false,
        text: 'Cliente',
        value: 'cliente',
      },
      {
        habilited: true,
        block: false,
        text: 'SubProduto',
        value: 'pedidos_agendados.sub_produto',
      },
      {
        habilited: true,
        block: false,
        text: 'Quantidade (KG)',
        value: 'pedidos_agendados.quantidade',
      },
      {
        habilited: true,
        block: false,
        text: 'Embalagem',
        value: 'pedidos_agendados.embalagem',
      },
      {
        habilited: true,
        block: false,
        text: 'Terminal',
        value: 'terminal',
      },
      {
        habilited: true,
        block: false,
        text: 'Terminal Destino',
        value: 'terminal_destino',
      },
      {
        habilited: true,
        block: false,
        text: 'Baixa automática',
        value: 'baixa_automatica',
      },
      {
        habilited: true,
        block: false,
        text: 'Região',
        value: 'regiao',
      },
      {
        habilited: true,
        block: false,
        text: 'Eixos',
        value: 'eixos',
      },

      {
        habilited: false,
        block: false,
        text: 'Motorista',
        value: 'motorista',
      },
      {
        habilited: false,
        block: false,
        text: 'Proprietário',
        value: 'proprietario',
      },
      {
        habilited: false,
        block: false,
        text: 'Transportadora',
        value: 'transportadora',
      },
      {
        habilited: false,
        block: false,
        text: 'Remetente',
        value: 'remetente',
      },
      {
        habilited: false,
        block: false,
        text: 'Destinatário',
        value: 'destinatario',
      },
      {
        habilited: false,
        block: false,
        text: 'Nº Pedido',
        value: 'pedidos_agendados.num_pedido',
      },
      {
        habilited: false,
        block: false,
        text: 'Nº Nota fiscal',
        value: 'nota_fiscal',
      },
      {
        habilited: false,
        block: false,
        text: 'Produto',
        value: 'pedidos_agendados.produto',
      },

      {
        habilited: false,
        block: false,
        text: 'Návio',
        value: 'navio',
      },
      {
        habilited: false,
        block: false,
        text: 'Tipo Frete',
        value: 'tipo_frete',
      },
      {
        habilited: false,
        block: false,
        text: 'Saída do Pátio',
        value: 'saida_do_patio',
      },
      {
        habilited: false,
        block: false,
        text: 'Chegada no terminal',
        value: 'chegada_terminal',
      },
      { habilited: false, block: false, text: 'DI', value: 'di' },

      { habilited: false, block: false, text: 'Píer', value: 'pier' },
      {
        habilited: false,
        block: false,
        text: 'Observação',
        value: 'observacao',
      },
    ],
    breadcrumbs: [
      {
        text: 'Logística',
        disabled: false,
      },
      {
        text: 'Relatório Carga/Descarga',
        disabled: false,
      },
    ],
  }
}
