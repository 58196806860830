<template>
  <div>
    <v-dialog v-model="dialog" max-width="50%" persistent>
      <v-card>
        <v-card-title>
          <span class="text-h5">{{ title }}</span>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="$emit('close')"> Fechar </v-btn>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col>
                <v-text-field
                  label="Data de entrada"
                  required
                  v-model="cobranca.data_entrada"
                  :disabled="true"
                >
                </v-text-field>
              </v-col>
              <v-col>
                <v-text-field
                  label="Data de saída"
                  required
                  v-model="cobranca.data_saida"
                  :disabled="true"
                >
                </v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-text-field
                  label="Total de horas"
                  required
                  v-model="cobranca.total_horas"
                  :disabled="true"
                >
                </v-text-field>
              </v-col>
              <v-col>
                <v-text-field
                  label="Total de horas adicionais"
                  required
                  v-model="cobranca.total_horas_adicionais"
                  :disabled="true"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-autocomplete
                  v-model="cobranca.servico"
                  :items="servicos"
                  item-text="servico.descricao"
                  item-value="id"
                  label="Serviço"
                  @change="calculaValor"
                  return-object
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <vuetify-money
                  label="Valor adicional"
                  required
                  v-model="cobranca.valor_adicional"
                  :disabled="true"
                  :options="options"
                ></vuetify-money>
              </v-col>
              <v-col>
                <v-select
                  label="Forma de Pagamento"
                  :items="formasPagamento"
                  item-text="descricao"
                  item-value="descricao"
                  v-model="cobranca.forma_pagamento"
                  return-object
                />
              </v-col>
              <v-col v-if="
                        cobranca.forma_pagamento &&
                        cobranca.forma_pagamento.is_convenio
                      ">
                <v-select
                    label="Transportadora"
                    :items="theTransportadoras"
                    return-object
                    
                    v-model="cobranca.transportadora"
                    :item-text="
                      (item) =>
                        item.transportadora.nome +
                        ' - ' +
                        `${
                          item.transportadora.cnpj != null
                            ? item.transportadora.cnpj
                            : item.transportadora.cpf
                        }`
                    "
                    item-value="public_id"
                  />
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="secondary"
            @click="save"
            :disabled="
              !cobranca.forma_pagamento ||
              !cobranca.servico
            "
            >Salvar</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex'
import { parseDatetime } from '@/mixins/convertion.js'
import patioApi from '@/services/patio'
import { Charge } from '@/utils/agendamento'

export default {
  name: 'Cobranca',
  mixins: [parseDatetime],
  props: {
    dialog: {
      type: Boolean,
      required: true,
    },
    agendamento: {
      type: Object,
      required: true,
    },
    HORAS_TOLERADAS: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      title: 'Horas Adicionais',
      options: {
        locale: 'pt-BR',
        prefix: 'R$',
        suffix: '',
        length: 7,
        precision: 2,
      },
      cobranca: {
        data_entrada: '',
        data_saida: '',
        _total_horas: '',
        total_horas: '',
        total_horas_adicionais: '',
        _total_horas_adicionais: '',
        _valor_adicional: '',
        valor_adicional: '',
        transportadora: null,
        forma_pagamento: null,
        servico: null,
      },
    }
  },
  watch: {
    agendamento(newAgendamento) {
      this.carregar(newAgendamento)
    },
  },
  computed: {
    ...mapState('patio', ['convenios', 'conveniosNaoAtivos']),
    ...mapGetters('formaPagamento', ['formasPagamento']),
    ...mapState('auth', ['empresaAtual']),
    theTransportadoras() {
      return this.convenios
    },
    servicos() {
      if (this.agendamento.fornecedor) {
        if (this.agendamento.fornecedor.servicos_autorizados) {
          return this.agendamento.fornecedor.servicos_autorizados.filter(
            (item) => item.servico.tipoTarifa == 'hora'
          )
        }
        return []
      }
      return []
    },
  },

  methods: {
    ...mapActions('patio', ['getConvenios']),
    ...mapActions('formaPagamento', ['getFormasPagamento']),
    bonificaServico(servico) {
      if (this.agendamento.vaga && this.agendamento.vaga.setor.bonificada) {
        if ('bonificado' in servico.config) {
          return Boolean(servico.config.bonificado)
        }
      }
      return false
    },
    carregar(agendamento) {
      const total_horas =
        (agendamento.data_saida - agendamento.data_entrada) / (1000 * 60 * 60)
      this.cobranca._data_entrada = agendamento.data_entrada
      this.cobranca.data_entrada = this.parseDatetime(agendamento.data_entrada)
      this.cobranca._data_saida = agendamento.data_saida
      this.cobranca.data_saida = this.parseDatetime(agendamento.data_saida)
      this.cobranca._total_horas = parseFloat(total_horas).toFixed(2)
      this.cobranca.total_horas = parseFloat(
        this.cobranca._total_horas
      ).toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })

      this.cobranca._total_horas_adicionais = parseFloat(total_horas).toFixed(2)

      this.cobranca.total_horas_adicionais = parseFloat(
        this.cobranca._total_horas_adicionais
      ).toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })
      this.cobranca.valor_adicional = 0
      this.cobranca.servico = null
      this.cobranca.forma_pagamento = null
    },
    async calculaValor() {
      // calculando o tempo em que o caminhao ficou no patio
      let entrada = new Date(this.cobranca._data_entrada)
      let saida = new Date(Date.now())
      let tempo_empatio = (saida - entrada) / 3600000
      tempo_empatio = tempo_empatio + 1
      let tarifas = null
      
      // buscado a tarifa pelo tempo que o caminhao ficou no patio
      if (
        this.cobranca.servico.servico.config.charge === Charge.Range
      ) {
        tarifas = await patioApi.getServicoTarifaHora({
          servico: this.cobranca.servico.servico.id,
          proprietario: this.empresaAtual.public_id,
          tolerancia: parseInt(tempo_empatio),
        })
      } else {
        tarifas = await patioApi.getServicoTarifaHora({
          servico: this.cobranca.servico.servico.id,
          proprietario: this.empresaAtual.public_id,
          tolerancia: 0,
        })
      }
      // setando o valor da tarifa
      if (tarifas.length > 0) {
        if (
          this.cobranca.servico.servico.config.charge === Charge.Range || this.cobranca.servico.servico.config.charge === Charge.EndFixed
        ) {
          this.cobranca._valor_adicional = tarifas[0].valor
        } else {
          if (
          this.empresaAtual.public_id == '21fcdd4f-38ec-4901-96a0-915616c84ed7' &&
          this.cobranca.servico.servico.config.charge !== Charge.End
          ) {
            this.cobranca._valor_adicional = parseFloat(
              100
            ).toFixed(2)
          } else {
            if (this.cobranca.servico.servico.config.charge === Charge.End) {
              if(tempo_empatio > parseFloat(tarifas[0].excedente[0].valor).toFixed(2)){
                this.cobranca.total_horas_adicionais = Math.ceil(parseFloat(this.cobranca._total_horas) - parseFloat(tarifas[0].excedente[0].doravante))
                let valorPadrao = parseFloat(parseFloat(tarifas[0].valor))
                let valorTotal = parseFloat(parseFloat(this.cobranca.total_horas_adicionais) * parseFloat(tarifas[0].excedente[0].valor))
                this.cobranca._valor_adicional = parseFloat(valorPadrao + valorTotal).toFixed(2)
                this.cobranca.total_horas_adicionais = parseFloat(this.cobranca.total_horas_adicionais).toFixed(2)
              }else{
                this.cobranca._valor_adicional = parseFloat(parseFloat(tarifas[0].valor).toFixed(2)).toFixed(2)
              }
            }else{
              this.cobranca.total_horas_adicionais = parseFloat(this.cobranca.total_horas_adicionais) - parseFloat(tarifas[0].excedente[0].doravante)
              this.cobranca._valor_adicional = parseFloat(
                parseFloat(this.cobranca.total_horas_adicionais) * parseFloat(tarifas[0].excedente[0].valor)
              ).toFixed(2)
              this.cobranca.total_horas_adicionais = parseFloat(this.cobranca.total_horas_adicionais).toFixed(2)
            }
          }
        }
      } else {
        this.cobranca._valor_adicional = 0
        this.cobranca._valor_adicional = 0
      }

      if(this.bonificaServico(this.cobranca.servico.servico)){
        this.cobranca._valor_adicional = 0
      }

      this.cobranca.valor_adicional = parseFloat(
        this.cobranca._valor_adicional
      ).toFixed(2)

    },
    async save() {
      this.$emit('cobrar', this.cobranca, this.agendamento)
    },
  },

  async created() {
    await this.getFormasPagamento()
    await this.getConvenios()
  },
}
</script>
