import { mapActions, mapMutations } from 'vuex'

import PatioApi from '@/services/patio'
import TipoAgendamento from '@/utils/agendamento'
import contratoAPI from '@/services/tenants/contratos'
import Perfil from '@/utils/perfil'

export const methods = {
  ...mapActions('patio', [
    'cobranca_adicional',
    'nextStep',
    'previousStep',
    'cancelarAgendamento',
    'startPauseAgendamento',
    'stopPauseAgendamento',
    'resetarClassificacao',
    'finalizaClassificacao',
    'confirmaSaidaIport',
    'enviarComandoCancela',
    'enviaClassificacao',
    'enviaPortoNet',
    'cadastraMotoristaRonda',
    'confirmaChegadaIport',
    'estornarStatusInicial',
    'autorizarAgendamento',
    'estornarCancelamento',
    'recusarAgendamento',
    'saidaBuffer',
    'entradaBuffer',
    'getLaudoLimpezaAgendamento',
    'getItensLaudoCheckList',
    'getAgendamentoGerenciamentoServico',
    'getMotivosCancelamento',
    'addLaudoItemCheckList',
    'editLaudoItemCheckList',
  ]),
  ...mapActions('template', [
    'successMessage',
    'errorMessage',
    'setDialogMessage',
  ]),
  ...mapMutations('agendamentos', ['ATUALIZA_AGENDAMENTO']),
  ...mapMutations('solicitacaoBaixa', ['SET_TERMINAIS']),
  ...mapActions('solicitacaoBaixa', [
    'getAgendamentosEmPatio',
    'addSolicitacaoBaixa',
  ]),
  laudoPreenchido(item) {
    if (!item) return false
    return item.laudo_limpeza
  },
  async getAgendamento(agendamento) {
    return await this.getAgendamentoGerenciamentoServico(agendamento)
  },
  async getDownload() {
    console.log('getDownload from _methods listagem')
    await this.$emit('getDownload')
  },
  converteKg(item) {
    return (parseFloat(item) * 1000).toFixed(0)
  },
  agendamentoNaoConcluido(agendamento) {
    return agendamento.status?.toLowerCase() !== this.statusConcluido
  },
  agendamentoNaoTriado(agendamento) {
    return agendamento.status?.toLowerCase() !== this.statusTriado
  },
  podePesoExato(agendamento) {
    return agendamento.pesagem_tara || agendamento.pesagem_bruto
  },
  async showModalChamarVeiculos() {
    // Verifica se há itens selecionados
    if (this.selecionados.length === 0) {
      console.log('Selecione pelo menos um caminhão.')
      return
    }

    // Obtém o último item selecionado e cria o objeto agendamento
    const ultimoSelecionado = this.selecionados[this.selecionados.length - 1]
    const agendamento = { public_id: ultimoSelecionado }

    try {
      // Obtém o agendamento detalhado
      const respostaAgendamento = await this.getAgendamento(agendamento)
      // Verifica se a resposta não é undefined e contém as propriedades esperadas
      if (
        !respostaAgendamento ||
        !respostaAgendamento.fornecedor ||
        !respostaAgendamento.gate
      ) {
        throw new Error('Resposta de agendamento inválida.')
      }

      // Configura os terminais
      await this.setTerminais(
        respostaAgendamento.fornecedor.fornecedor_id, // Certifique-se de que este é o campo correto
        respostaAgendamento.gate.public_id
      )

      // Mostra o modal após o processamento bem-sucedido
      this.modalChamarCaminhoes = true
    } catch (error) {
      // Trata erros e exibe mensagem no console
      console.error('Erro ao processar o agendamento:', error)
    }
  },
  async showModalCancelarVeiculos() {
    // Verifica se há itens selecionados
    if (this.selecionados.length === 0) {
      console.log('Selecione pelo menos um caminhão.')
      return
    }
    this.modalCancelarCaminhoes = true
  },
  async setTerminais(tenantPublicID, gatePublicID) {
    try {
      if (this.ehFornecedor || this.ehGate) {
        this.terminais = await contratoAPI.getContratos(tenantPublicID, {
          perfil: Perfil.Terminal,
        })

        const terminais_gate = await contratoAPI.getContratos(gatePublicID, {
          perfil: Perfil.Terminal,
        })

        const terminais_map = this.terminais.map((item) => item.cliente_id)

        this.terminais = [].concat(
          this.terminais,
          terminais_gate.filter(
            (terminal) => !terminais_map.includes(terminal.cliente_id)
          )
        )
      } else {
        const retorno = await contratoAPI.getContratos(tenantPublicID, {
          perfil: Perfil.Terminal,
        })

        this.terminais = retorno.filter(
          (terminal) => terminal.cliente_id === this.empresaAtual.public_id
        )
      }

      this.SET_TERMINAIS(this.terminais)
    } catch (e) {
      this.errorMessage(e.response.data)
    }
  },
  async chamarCaminhoes() {
    this.$emit('loading')
    try {
      const data = this.selecionados.map((triagem) => ({
        triagem,
        proprietario: this.empresaAtual.public_id,
        terminal_destino: this.terminalDestino,
      }))
      await this.addSolicitacaoBaixa(data)
      this.modalChamarCaminhoes = false
      this.quantidade = null

      this.selecionados.forEach(async (item) => {
        const agendamento = this.data.find((i) => (i.public_id = item))
        const { status, _etapa_atual } = await this.getAgendamento(agendamento)
        agendamento.status = status
        agendamento.etapa_atual = _etapa_atual
      })

      this.selecionados = []
      this.$nextTick(() =>
        this.successMessage('Solicitação realizada com sucesso!')
      )
    } catch (e) {
      this.errorMessage(e.response.data)
    }
  },
  async cancelarCaminhoes() {
    this.modalCancelarCaminhoes = false
    this.$emit('loading')
    Object.assign(this.cancelamento, {
      success: 0,
      error: 0,
      processed: 0,
      data: [],
    })
    this.cancelamento.quantidade = this.selecionados.length
    this.modalCancelandoCaminhoes = true
    for (const selecionado of this.selecionados) {
      try {
        const data = await this.cancelarAgendamento({
          tenant: this.empresaAtual,
          agendamento: { public_id: selecionado, cancelamento_em_massa: true },
        })
        this.cancelamento.data.push(data)
        this.cancelamento.success += 1
      } catch (error) {
        console.log(error)
        this.cancelamento.error += 1
      } finally {
        this.cancelamento.processed += 1
      }
    }

    this.$emit('loaded')
  },
  agendamentoAgendado(agendamento) {
    return agendamento.status?.toLowerCase() === this.statusAgendado
  },
  getTempoEmPatio(item) {
    if (item.triado_em !== null) {
      const diferencaEmMs = new Date() - Date.parse(item.triado_em)
      const tempo = diferencaEmMs / 1000
      const horas = Math.floor(tempo / (60 * 60))
      const minutos = Math.floor((tempo % (60 * 60)) / 60)
      return `${horas
        .toString()
        .padStart(2, '0')}:${minutos.toString().padStart(2, '0')}`
    } else {
      return '00:00'
    }
  },
  getTempo(item) {
    if (item !== null) {
      const diferencaEmMs = new Date() - Date.parse(item)
      const segundos = diferencaEmMs / 1000
      return segundos
    } else {
      return 0
    }
  },
  itemRowBackground: function (item) {
    let color = 'style-1'
    if (item.triado_em !== null) {
      const diferencaEmMs = new Date() - Date.parse(item.triado_em)
      const segundos = diferencaEmMs / 1000
      const horas = segundos / 3600
      // color = (horas > 8 ) ? 'style-2' : 'style-1';
      color = horas > 0 ? 'style-1' : 'style-1'
    }
    return color
  },
  exigeConferenciaAgendamento(agendamento) {
    if (
      agendamento.config_fornecedor &&
      agendamento.config_fornecedor.agendamento &&
      agendamento.config_fornecedor.agendamento.exigeConferenciaAgendamento
    ) {
      if (agendamento.auto_ent_triagem) {
        return false
      } else {
        alert('Fornecedor exige conferecia dos dados!')
        return true
      }
    } else {
      return false
    }
  },
  podeTriar(agendamento) {
    if (agendamento.gate_id != this.empresaAtual.public_id) {
      return false
    }
    if (this.canBypassDate) return true
    const now = new Date()
    if (agendamento.data_cota) {
      const data_cota = new Date(agendamento.data_cota.data_fim)
      if (now > data_cota) return false
    }
    if (agendamento.data_pedido) {
      const data_pedido = new Date(agendamento.data_pedido + 'T23:59:59')
      if (now > data_pedido) return false
    }

    return true
  },
  async handleGerarTriagem(item) {
    if (this.exigeConferenciaAgendamento(item)) {
      return
    }

    const agendamentoSelecionado = await this.getAgendamento(item)
    agendamentoSelecionado.isEdicao = false
    agendamentoSelecionado.isEdicaoTriagem = false
    this.$router.push({
      name: 'NovaTriagem',
      params: {
        agendamentoSelecionado,
        tipoAgendamento: TipoAgendamento.Triagem,
      },
    })
  },
  async handleEnviaClassificacao(item) {
    const agendamento = await this.getAgendamento(item)

    try {
      let retorno = { statusMessage: '', classifWebId: '' }

      if (
        agendamento.fornecedor.public_id ==
        '11bdbc50-57c0-40db-bd96-f28a02b964b5'
      ) {
        retorno = await this.confirmaChegadaIport(agendamento)
      } else {
        retorno = await this.enviaClassificacao(agendamento)
      }

      item.statusMessage = retorno.statusMessage
      item.classifWebId = retorno.classifWebId

      this.successMessage('Comando enviado com sucesso!')
    } catch (err) {
      if (err.response && err.response.status == 400) {
        this.errorMessage(err.response.data)
        item.statusMessage = err.response.data.statusMessage
        item.classifWebId = err.response.data.classifWebId
      } else {
        this.errorMessage(err)
      }
    }
  },
  async handleEnviaPortoNet(item) {
    const agendamento = await this.getAgendamento(item)

    try {
      let retorno = { statusMessage: '', classifWebId: '' }

      retorno = await this.enviaPortoNet(agendamento)

      item.statusMessageIntegracao = retorno.statusMessage
      item.idAgendamentoExternoIntegracao = retorno.classifWebId

      this.successMessage('Comando enviado com sucesso!')
    } catch (err) {
      if (err.response && err.response.status == 400) {
        this.errorMessage(err.response.data)
        item.statusMessage = err.response.data.statusMessage
        item.classifWebId = err.response.data.classifWebId
      } else {
        this.errorMessage(err)
      }
    }
  },
  async handleCadastraMotoristaRonda(item) {
    try {
      const agendamento = await this.getAgendamento(item)

      await this.cadastraMotoristaRonda(agendamento)

      this.successMessage('Enviado motorista ao sistema do ronda!')
    } catch (err) {
      this.errorMessage(err)
    }
  },
  async handleEnviarComandoCancela(item) {
    try {
      const agendamento = await this.getAgendamento(item)
      const { status, prestador } = await this.enviarComandoCancela(agendamento)
      agendamento.status = status
      agendamento.etapa_atual = { prestador }

      this.successMessage('Comando enviado com sucesso!')
    } catch (err) {
      if (err.response && err.response.status == 400) {
        this.errorMessage(err.response.data)
      } else {
        this.errorMessage(err)
      }
    }
  },
  async handleResetarClassificacao(item) {
    try {
      const agendamento = await this.getAgendamento(item)
      await this.resetarClassificacao(agendamento)

      this.successMessage('Classificação resetada!')
    } catch (err) {
      this.errorMessage(err)
    }
  },
  async handleFinalizaClassificacao(item) {
    try {
      const agendamento = await this.getAgendamento(item)
      if (
        agendamento.fornecedor.public_id ==
        '11bdbc50-57c0-40db-bd96-f28a02b964b5'
      ) {
        await this.confirmaSaidaIport(agendamento)
      } else {
        await this.finalizaClassificacao(agendamento)
      }

      this.successMessage('Classificação resetada!')
    } catch (err) {
      this.errorMessage(err)
    }
  },
  async handleEditarAgendamento(item) {
    const agendamentoSelecionado = await this.getAgendamento(item)
    let tipo_agendamento = TipoAgendamento.EdicaoTriado

    if (!agendamentoSelecionado.triado) {
      agendamentoSelecionado.isEdicao = true
      agendamentoSelecionado.isEdicaoTriagem = false
      tipo_agendamento = TipoAgendamento.EdicaoAgendado
    } else {
      agendamentoSelecionado.isEdicaoTriagem = true
      agendamentoSelecionado.isEdicao = false
      tipo_agendamento = TipoAgendamento.EdicaoTriado
    }

    this.$router.push({
      name: 'NovaTriagem',
      params: {
        agendamentoSelecionado,
        tipoAgendamento: tipo_agendamento,
      },
    })
  },
  async handleEstornarStatusInicial(item) {
    try {
      const agendamentoEstornar = await this.getAgendamento(item)
      await this.estornarStatusInicial({
        agendamento: agendamentoEstornar,
      })

      this.successMessage('Agendamento estornado com sucesso!')
    } catch (err) {
      this.errorMessage(err.response.data)
    }
  },
  async handleEstornarCancelamento(item) {
    try {
      const agendamentoEstornar = await this.getAgendamento(item)
      await this.estornarCancelamento({
        agendamento: agendamentoEstornar,
      })

      this.successMessage('Agendamento estornado com sucesso!')
    } catch (err) {
      this.errorMessage(err.response.data)
    }
  },

  async handleAutorizarAgendamento(item) {
    try {
      const agendamentoCompleto = await this.getAgendamento(item)

      await this.autorizarAgendamento({
        agendamento: agendamentoCompleto,
      })

      this.successMessage('Agendamento autorizado com sucesso!')
    } catch (err) {
      this.errorMessage(err.response.data)
    }
  },

  async handleRecusarAgendamento(item) {
    try {
      const agendamentoCompleto = await this.getAgendamento(item)
      await this.recusarAgendamento({
        agendamento: agendamentoCompleto,
      })

      this.successMessage('Agendamento recusado com sucesso!')
    } catch (err) {
      this.errorMessage(err.response.data)
    }
  },

  async handleSaidaBuffer(item) {
    try {
      const agendamentoSaidaBuffer = await this.getAgendamento(item)
      const data = await this.saidaBuffer({
        agendamento: agendamentoSaidaBuffer,
      })
      this.successMessage(data.statusMessage)
    } catch (err) {
      this.errorMessage(err)
    }
  },
  async confirmDelete() {
    try {
      const agendamentoSaidaBuffer = await this.getAgendamento(
        this.agendamentoEncerrarProcesso
      )
      const data = await this.saidaBuffer({
        agendamento: agendamentoSaidaBuffer,
      })
      this.successMessage(data.statusMessage)
    } catch (e) {
      this.errorMessage(e.response.data)
    }
  },
  async handleEncerrarViagem(item) {
    const agendamento = await this.getAgendamento(item)

    item.data_entrada = new Date(agendamento.triado_em)
    item.data_saida = new Date()
    item.fornecedor = {
      servicos_autorizados: agendamento.fornecedor.servicos_autorizados,
    }
    item.agendamento = agendamento
    const diferencaEmMs = new Date() - Date.parse(agendamento.triado_em)
    const segundos = diferencaEmMs / 1000
    const horas = segundos / 3600
    let tolerancia = 0

    if (agendamento.gate.cnpj == '12806215000183') {
      tolerancia = 72
    }

    if (agendamento.gate.cnpj == '16891862000191') {
      tolerancia = 6
    }

    if (
      agendamento._etapa_atual &&
      agendamento._etapa_atual.cobranca &&
      horas > tolerancia
    ) {
      this.openModalCobranca(item)
    } else {
      try {
        const { status, prestador, _etapa_atual } = await this.nextStep(
          agendamento
        )
        item.status = status
        item.etapa_atual = { prestador }
        item._etapa_atual = { ..._etapa_atual }

        this.successMessage('Etapa avançada!')
      } catch (error) {
        if (error.response) {
          this.errorMessage(error.response.data)
          return
        }
        if (error.message) {
          this.errorMessage(error.message)
          return
        }
        this.errorMessage(error)
      }
    }

    this.agendamentoEncerrarProcesso = agendamento

    this.setDialogMessage({
      title: 'Saida buffer!',
      message: 'Deseja realmente confirmar a saida buffer?',
    })
  },

  async handleEntradaBuffer(item) {
    try {
      const agendamentoEntradaBuffer = await this.getAgendamento(item)
      const data = await this.entradaBuffer({
        agendamento: agendamentoEntradaBuffer,
      })
      this.successMessage(data.statusMessage)
    } catch (error) {
      console.log(error)
      window.error = error
      if (error.response) {
        this.errorMessage(error.response.data)
      } else {
        this.errorMessage(error)
      }
    }
  },

  async handleVisualizarComprovantes(item) {
    const agendamento = await this.getAgendamento(item)
    this.ATUALIZA_AGENDAMENTO(agendamento)
    this.visualizarComprovantes()
  },
  async handleVisualizarComprovante(item) {
    const agendamento = await this.getAgendamento(item)
    this.ATUALIZA_AGENDAMENTO(agendamento)
    this.visualizarComprovante()
  },
  async handleVisualizarComprovantePesagem(item) {
    const agendamento = await this.getAgendamento(item)
    this.ATUALIZA_AGENDAMENTO(agendamento)
    this.visualizarComprovantePesagem()
  },
  async handleVisualizarComprovanteAgendamento(item) {
    const agendamento = await this.getAgendamento(item)
    this.ATUALIZA_AGENDAMENTO(agendamento)
    this.visualizarComprovanteAgendamento()
  },
  async handleVisualizarLogsIntegracoes(item) {
    const agendamento = await this.getAgendamento(item)
    this.ATUALIZA_AGENDAMENTO(agendamento)
    this.visualizarLogsIntegracoes()
  },
  async handleVisualizarOrdemCarga(item) {
    const agendamento = await this.getAgendamento(item)
    this.ATUALIZA_AGENDAMENTO(agendamento)
    this.visualizarOrdemDeCarga()
  },
  async handleVisualizarDocumentos(item) {
    const agendamento = await this.getAgendamento(item)
    this.ATUALIZA_AGENDAMENTO(agendamento)
    this.visualizarDocumentos()
  },
  async openModalGerarChecklistLaudo(item) {
    const agendamento = await this.getAgendamento(item)
    this.agendamentoLaudo = agendamento

    try {
      this.listItensCheckList = await this.getItensLaudoCheckList(agendamento)
    } catch (e) {
      if (e.response) {
        this.errorMessage(e.response.data)
        return
      }
      if (e.message) {
        this.errorMessage(e.message)
        return
      }
      this.errorMessage(e)
    }

    this.dialogGerarChecklistLaudo = true
  },
  async openModalEdicaoRapida(item) {
    const agendamento = await this.getAgendamento(item)
    this.agendamentoEdicaoRapida = agendamento
    this.dialogEdicaoRapida = true
  },
  async handleVisualizarComprovanteLaudo(item) {
    const agendamento = await this.getAgendamento(item)
    try {
      const laudo_limpeza = await this.getLaudoLimpezaAgendamento(agendamento)
      agendamento.laudo_limpeza = laudo_limpeza
      this.ATUALIZA_AGENDAMENTO(agendamento)
      this.visualizarComprovanteLaudo()
    } catch (e) {
      if (e.response) {
        this.errorMessage(e.response.data)
        return
      }
      if (e.message) {
        this.errorMessage(e.message)
        return
      }
      this.errorMessage(e)
    }
  },
  async handleNextStep(item) {
    const agendamento = await this.getAgendamento(item)
    item.data_entrada = new Date(agendamento.triado_em)
    item.data_saida = new Date()
    item.fornecedor = {
      servicos_autorizados: agendamento.fornecedor.servicos_autorizados,
    }
    item.agendamento = agendamento
    const diferencaEmMs = new Date() - Date.parse(agendamento.triado_em)
    const segundos = diferencaEmMs / 1000
    const horas = segundos / 3600
    let tolerancia = 0
    let tarifas = null
    let abreModal = false

    if (agendamento._etapa_atual && agendamento._etapa_atual.cobranca) {
      if (agendamento.servicos_triados[0].servico.tipoTarifa == 'hora') {
        tarifas = await PatioApi.getServicoTarifaHora({
          servico: agendamento.servicos_triados[0].servico.id,
          proprietario: agendamento.gate.public_id,
        })
      }

      if (tarifas != null) {
        tolerancia = tarifas[0].tolerancia
      }

      if (tolerancia == 0) {
        let servico = null
        if (item.fornecedor.servicos_autorizados) {
          servico = item.fornecedor.servicos_autorizados.filter(
            (item) => item.servico.tipoTarifa == 'hora'
          )
        }

        tarifas = await PatioApi.getServicoTarifaHora({
          servico: servico.id,
          proprietario: agendamento.gate.public_id,
        })
      }

      if (tarifas != null) {
        tolerancia = tarifas[tarifas.length - 1].tolerancia
      }

      if (agendamento.gate.cnpj == '16891862000191') {
        tolerancia = 6
      }

      if (
        agendamento._etapa_atual &&
        agendamento._etapa_atual.cobranca &&
        horas > tolerancia
      ) {
        abreModal = true
      }
    }

    if (abreModal) {
      this.openModalCobranca(item)
    } else {
      try {
        const { status, prestador, _etapa_atual } = await this.nextStep(
          agendamento
        )
        item.status = status
        item.etapa_atual = { prestador }
        item._etapa_atual = { ..._etapa_atual }

        this.successMessage('Etapa avançada!')
      } catch (error) {
        if (error.response) {
          this.errorMessage(error.response.data)
          return
        }
        if (error.message) {
          this.errorMessage(error.message)
          return
        }
        this.errorMessage(error)
      }
    }
  },
  async handlePreviousStep(item) {
    try {
      const agendamento = await this.getAgendamento(item)
      const { status, prestador, _etapa_atual } = await this.previousStep(
        agendamento
      )
      item.status = status
      item.etapa_atual = { prestador }
      item._etapa_atual = { ..._etapa_atual }

      this.successMessage('Etapa recuada!')
    } catch (error) {
      if (error.response) {
        this.errorMessage(error.response.data)
        return
      }
      if (error.message) {
        this.errorMessage(error.message)
        return
      }
      this.errorMessage(error)
    }
  },
  setMotivo(e, motivo) {
    if (e.currentTarget.checked) {
      let selecionados = (this.agendamentoCancelar.motivo_cancelamento || '')
        .split(', ')
        .filter((item) => item != '')
      selecionados.push(motivo)
      this.agendamentoCancelar.motivo_cancelamento = selecionados.join(', ')
    } else {
      let selecionados = (this.agendamentoCancelar.motivo_cancelamento || '')
        .split(', ')
        .filter((item) => item != motivo)
      this.agendamentoCancelar.motivo_cancelamento = selecionados.join(', ')
    }
  },
  async handleGerarLaudo() {
    try {
      //Save items check list
      const items_add = []
      const items_edit = []
      for (var i = 0; i < this.listItensCheckList.length; i++) {
        this.cleanObject(this.editedItemCheckList)
        this.editedItemCheckList.public_id = this.listItensCheckList[
          i
        ].public_id
        this.editedItemCheckList.laudo_limpeza = this.listItensCheckList[
          i
        ].laudo_limpeza_id
        this.editedItemCheckList.item_laudo_checklist = this.listItensCheckList[
          i
        ].item_laudo_checklist_id
        this.editedItemCheckList.observation = this.listItensCheckList[
          i
        ].observation

        if (this.listItensCheckList[i].selectedItemKey == 1) {
          this.editedItemCheckList.check = true
        } else if (this.listItensCheckList[i].selectedItemKey == 2) {
          this.editedItemCheckList.check = false
        }

        if (
          this.listItensCheckList[i].public_id == null ||
          this.listItensCheckList[i].public_id == ''
        ) {
          items_add.push({ ...this.editedItemCheckList })
          // this.addLaudoItemCheckList(this.editedItemCheckList)
        } else {
          items_edit.push({ ...this.editedItemCheckList })
          // this.editLaudoItemCheckList(this.editedItemCheckList)
        }
      }

      if (items_add.length > 0) {
        await this.addLaudoItemCheckList(items_add)
      }
      if (items_edit.length > 0) {
        await this.addLaudoItemCheckList(items_edit)
      }

      this.dialogGerarChecklistLaudo = false

      //Get laudo
      const laudo_limpeza = await this.getLaudoLimpezaAgendamento(
        this.agendamentoLaudo
      )
      this.agendamentoLaudo.laudo_limpeza = laudo_limpeza
      this.ATUALIZA_AGENDAMENTO(this.agendamentoLaudo)
      this.visualizarComprovanteLaudo()
    } catch (e) {
      if (e.response) {
        this.errorMessage(e.response.data)
        return
      }
      if (e.message) {
        this.errorMessage(e.message)
        return
      }
      this.errorMessage(e)
    }
  },

  async handleCancelarAgendamento() {
    try {
      if (
        this.selectMotivoCancelamento != undefined ||
        this.selectMotivoCancelamento != ''
      ) {
        this.agendamentoCancelar.motivo_cancelamento = this.motivosCancelamentoObservacao
        this.agendamentoCancelar.motivo_cancelamento_public_id = this.selectMotivoCancelamento
      }
      let data = { status: '' }

      data = await this.cancelarAgendamento({
        tenant: this.empresaAtual,
        agendamento: this.agendamentoCancelar,
      })
      this.successMessage(data['status'])
    } catch (err) {
      this.errorMessage(err.response.data)
    } finally {
      this.dialogCancelar = false
      this.agendamentoCancelar.motivo_cancelamento = ''
    }
  },
  async handlePauseAgendamento() {
    try {
      await this.startPauseAgendamento({
        tenant: this.empresaAtual,
        agendamento: this.agendamentoPause,
      })
      this.successMessage('Agendamento pausado com sucesso!')
      this.dialogPausar = false
    } catch (err) {
      this.errorMessage(err.response.data)
    } finally {
      this.dialogCancelar = false
      this.agendamentoPause.observacao = ''
    }
  },

  async handleStopPause(agendamento) {
    try {
      await this.stopPauseAgendamento({
        tenant: this.empresaAtual,
        agendamento: agendamento,
      })
      this.successMessage('Agendamento pausado com sucesso!')
    } catch (err) {
      this.errorMessage(err.response.data)
    } finally {
      this.dialogCancelar = false
      this.agendamentoPause.observacao = ''
    }
  },
  async handleCancelarServicoTriado() {
    try {
      await PatioApi.cancelarServicoTriado(
        this.empresaAtual,
        this.user,
        this.servicoTriadoCancelar
      )
      this.successMessage('Serviço cancelado com sucesso!')

      this.dialogServicosTriados = false
      this.dialogServicoTriadoCancelar = false
      this.servicoTriadoCancelar.motivo_cancelamento = ''
    } catch (err) {
      this.errorMessage(err.response.data)
    } finally {
      this.dialogServicosTriados = false
      this.dialogServicoTriadoCancelar = false
      this.servicoTriadoCancelar.motivo_cancelamento = ''
    }
  },
  async openDialogPesoExato(item) {
    const agendamento = await this.getAgendamento(item)
    this.dialogPesoExato = true
    this.agendamentoPesoExato = agendamento
  },
  async openDialogPesagemHistory(item) {
    this.dialogPesagemHistory = true
    const agendamento = await this.getAgendamento(item)
    this.historico = agendamento.pesagens
  },
  async openDialogPesagem(item) {
    const agendamento = await this.getAgendamento(item)
    this.agendamentoPesagem = agendamento
    this.dialogPesagem = true
  },
  async openDialogDetails(agendamento) {
    this.dialogDetails = true
    this.detalhes = await PatioApi.getAgendamentoHistorico(
      agendamento,
      this.empresaAtual
    )
  },
  async openDialogDetailsPause(agendamento) {
    this.dialogDetailsPause = true
    this.detalhesPause = await PatioApi.getAgendamentoHistoricoPause(
      agendamento,
      this.empresaAtual
    )
  },
  async openModalTrocarCota(item) {
    if (!item.triado && (item.programacao_cliente || item.programacao_regiao)) {
      const agendamento = await this.getAgendamento(item)
      item.detalhes = agendamento
      this.agendamentoTrocarCota = item
      this.$nextTick(() => {
        this.dialogTrocarCota = true
      })
    }
  },
  async openModalCancelarAgendamento(item) {
    const agendamento = await this.getAgendamento(item)
    this.agendamentoCancelar = agendamento
    await this.getMotivosCancelamento({
      proprietario: agendamento.gate.public_id,
    })
    this.dialogCancelar = true
  },
  async openModalPausarAgendamento(item) {
    const agendamento = await this.getAgendamento(item)
    this.agendamentoPause = agendamento
    this.dialogPausar = true
  },
  async openModalCancelarServicoTriado(item) {
    const agendamento = await this.getAgendamento(item)
    this.dialogServicosTriados = true

    this.servicosTriados = await PatioApi.getAgendamentoServicoTriado(
      agendamento
    )
  },
  cancelarServicoTriado(servicoTriado) {
    this.servicoTriadoCancelar = servicoTriado
    this.dialogServicoTriadoCancelar = true
  },
  openModalCobranca(agendamento) {
    this.agendamentoCobrar = agendamento
    this.dialogCobranca = true
  },
  async confirmarPesoExato() {
    const agendamento = this.agendamentoPesoExato
    console.log(agendamento)
    try {
      const extra = agendamento.extra
      if (!('agendamento' in extra)) {
        extra.agendamento = {}
      }
      extra.agendamento.peso_exato = !(extra.agendamento.peso_exato || false)
      await PatioApi.atualizarAgendamento(agendamento.proprietario, {
        public_id: agendamento.public_id,
        extra: extra,
      })
      this.successMessage('Agendamento atualizado!')
    } catch (error) {
      if (error.response) {
        this.errorMessage(error.response.data)
      } else if (error.message) {
        this.errorMessage(error.message)
      } else {
        this.errorMessage(error)
      }
    }
    this.dialogPesoExato = false
  },
  async cobrar(cobranca, item) {
    try {
      item.agendamento.data_saida = cobranca._data_saida
      item.agendamento.total_horas = cobranca._total_horas
      item.agendamento.total_horas_adicionais = cobranca._total_horas_adicionais
      item.agendamento.valor_adicional = cobranca._valor_adicional
      item.agendamento.descricao = cobranca.servico.servico.descricao

      const { status, prestador, _etapa_atual } = await this.cobranca_adicional(
        {
          agendamento: item,
          cobranca: cobranca,
        }
      )
      item.status = status
      item.etapa_atual = { prestador }
      item._etapa_atual = { ..._etapa_atual }
      this.closeModalCobranca()
      this.ATUALIZA_AGENDAMENTO(item.agendamento)
      this.visualizarComprovanteServicoAdicional()

      this.successMessage('Cobrança Efetuada!')
    } catch (err) {
      if (err.response && err.response.status == 400) {
        this.errorMessage(err.response.data)
      } else {
        this.errorMessage(err)
      }
    }
  },
  closeModalCobranca() {
    this.agendamentoCobrar = {}
    this.dialogCobranca = false
  },
  closeModalTrocarCota() {
    // this.agendamentoTrocarCota.data_cota = agendamento.data_cota
    // this.agendamentoTrocarCota.programacao_cliente = undefined
    // this.agendamentoTrocarCota.programacao_regiao = item.public_id
    this.agendamentoTrocarCota = {}
    this.dialogTrocarCota = false
  },
  closeModalPesagem() {
    this.agendamentoPesagem = {}
    this.dialogPesagem = false
  },
  cleanObject(obj) {
    var vazio = true
    for (var e in obj) {
      var k = obj[e]
      if (
        !k ||
        ((k instanceof Object || k instanceof Array) && this.cleanObject(k))
      ) {
        delete obj[e]
      } else {
        vazio = false
      }
    }
    return vazio
  },
  async getCustomDownload() {
    // Relatório de Recebimento Navio
    console.log('getCustomDownload from _methods listagem')
    await this.$emit('getCustomDownload')
  },
  async getCustomCargaDownload() {
    // Relatório de Expedição de Carga
    console.log('getCustomCargaDownload from _methods listagem')
    await this.$emit('getCustomCargaDownload')
  },

  async getCustomTransbordoDownload() {
    // Relatório de Transbordo Interno
    console.log('getCustomTransbordoDownload from _methods listagem')
    await this.$emit('getCustomTransbordoDownload')
  },
  async getCustomRodoviarioDownload() {
    // Relatório de Rodoviário
    console.log('getCustomRodoviarioDownload from _methods listagem')
    await this.$emit('getCustomRodoviarioDownload')
  },
  async getCustomExpedicaoRecebimentoDownload() {
    // Relatório de Expedição e Recebimento
    console.log('getCustomExpedicaoRecebimentoDownload from _methods listagem')
    await this.$emit('getCustomExpedicaoRecebimentoDownload')
  },

  async getCustomAcertoPesoDownload() {
    // Relatório de Acerto de Peso
    console.log('getCustomAcertoPesoDownload from _methods listagem')
    await this.$emit('getCustomAcertoPesoDownload')
  },

  async getCustomExpedicaoFabricaDownload() {
    // Relatório de Expedição de Fabrica
    console.log('getCustomExpedicaoFabricaDownload from _methods listagem')
    await this.$emit('getCustomExpedicaoFabricaDownload')
  },
}
