import { mapGetters, mapState } from 'vuex'

import { LOCAL_STORAGE_ID } from '@/utils/relatorioCargaDescarga'

export const computed = {
  ...mapState('auth', ['empresaAtual']),
  ...mapState('fornecedores', ['fornecedores']),
  ...mapState('clientes', ['clientes']),
  ...mapState('transportadoras', ['transportadoras']),
  ...mapGetters('contratos', ['terminais']),
  ...mapState('patio', ['regioes', 'representantes', 'tiposOperacao']),
  ...mapState('motoristas', ['motoristas']),

  showDataAgendamento() {
    return (
      this.params.data_agendamento_inicio?.visible ||
      this.params.data_agendamento_fim?.visible
    )
  },
  showDataTriagem() {
    return (
      this.params.data_triagem_inicio?.visible ||
      this.params.data_triagem_fim?.visible
    )
  },
  showDataCota() {
    return (
      this.params.data_cota_inicio?.visible ||
      this.params.data_cota_fim?.visible
    )
  },
  showDataPedido() {
    return (
      this.params.data_pedido_inicio?.visible ||
      this.params.data_pedido_fim?.visible ||
      this.params.numero_pedido?.visible
    )
  },

  showEmbalagem() {
    return this.params.embalagem?.visible
  },
  showDataPesagemEntrada() {
    return (
      this.params.data_pesagem_entrada_fim?.visible ||
      this.params.data_pesagem_entrada_inicio?.visible
    )
  },
  showDataPesagemSaida() {
    return (
      this.params.data_pesagem_saida_fim?.visible ||
      this.params.data_pesagem_saida_inicio?.visible
    )
  },
  showDataSaidaPatio() {
    return (
      this.params.data_saida_patio_inicio?.visible ||
      this.params.data_saida_patio_fim?.visible
    )
  },
  showDataConclusao() {
    return (
      this.params.data_conclusao_inicio?.visible ||
      this.params.data_conclusao_fim?.visible
    )
  },
  showFornecedor() {
    return (
      this.params.fornecedor.visible ||
      this.params.produto.visible ||
      this.params.sub_produto.visible
    )
  },
  showContratos() {
    return (
      this.params.terminal.visible ||
      this.params.cliente.visible ||
      this.params.transportadora.visible
    )
  },
  showRegiao() {
    return this.params.regiao.visible || this.params.representante.visible
  },
  showVeiculo() {
    return (
      this.params.motorista.visible ||
      this.params.placa_cavalo.visible ||
      this.params.eixo.visible
    )
  },
  showNavio() {
    return Boolean(this.params.navio.visible)
  },
  showServicos() {
    return Boolean(this.params?.servico_triado?.visible)
  },
  showTipoOperacao() {
    return this.params?.tipo_operacao?.visible
  },
  areSettingsEmpty() {
    return !(
      this.showDataAgendamento ||
      this.showDataTriagem ||
      this.showDataCota ||
      this.showDataPedido ||
      this.showDataSaidaPatio ||
      this.showDataPesagemEntrada ||
      this.showDataPesagemSaida ||
      this.showDataConclusao ||
      this.showFornecedor ||
      this.showContratos ||
      this.showRegiao ||
      this.showVeiculo ||
      this.showNavio ||
      this.showServicos ||
      this.showEmbalagem ||
      this.showTipoOperacao
    )
  },
  selectedOptions() {
    return JSON.parse(localStorage.getItem(LOCAL_STORAGE_ID)) || []
  },
  selectedFornecedor() {
    return this.params.fornecedor.value
  },
  selectedProduto() {
    return this.params.produto.value
  },
  // isRelatorioMaster() {
  //   const allowedCnpjs = [
  //     '00000000000001',
  //     '15011519000304',
  //     '15011519000142',
  //     '28631030000115',
  //   ]
  //   return allowedCnpjs.includes(this.empresaAtual?.cnpj)
  // },
  gate() {
    if (this.params.gate && this.params.gate.value) {
      return this.gates.find((i) => i.public_id == this.params.gate.value)
    }
    return
  },
}
